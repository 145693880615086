<template>
    <div class="icon-documentation">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 2.76953V6.90007C14 7.46012 14 7.74015 14.109 7.95406C14.2049 8.14222 14.3578 8.2952 14.546 8.39108C14.7599 8.50007 15.0399 8.50007 15.6 8.50007H19.7305M9.84998 12.5022C10.0262 12.0014 10.3739 11.579 10.8317 11.31C11.2895 11.0409 11.8277 10.9426 12.351 11.0324C12.8743 11.1221 13.349 11.3942 13.6909 11.8004C14.0329 12.2066 14.22 12.7207 14.2192 13.2517C14.2192 14.7506 11.9709 15.5 11.9709 15.5M12 18.5H12.01M14 2.5H8.8C7.11984 2.5 6.27976 2.5 5.63803 2.82698C5.07354 3.1146 4.6146 3.57354 4.32698 4.13803C4 4.77976 4 5.61984 4 7.3V17.7C4 19.3802 4 20.2202 4.32698 20.862C4.6146 21.4265 5.07354 21.8854 5.63803 22.173C6.27976 22.5 7.11984 22.5 8.8 22.5H15.2C16.8802 22.5 17.7202 22.5 18.362 22.173C18.9265 21.8854 19.3854 21.4265 19.673 20.862C20 20.2202 20 19.3802 20 17.7V8.5L14 2.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>
</template>

<script setup lang="ts">
</script>

<style scopped lang="scss">
    .icon-documentation {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);

        :hover{
            cursor: pointer;
        }
    }
</style>